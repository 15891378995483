/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Policy from 'shared/api/assurant/policy';
import { FileResponse, PolicyResponse } from 'shared/api/assurant/policy/types';
import { toast } from 'react-toastify';
import { ErrorList } from 'shared/ui/ErrorList/ErrorList';
import { AxiosError, AxiosResponse } from 'axios';
import ApplicationForm from '../../../shared/api';

export const useFileGetMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: ApplicationForm.getFile,
		onSuccess: (data: AxiosResponse<FileResponse>) => {
			queryClient.setQueryData(['file'], data.data)
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}

export const useFileMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: ApplicationForm.createFile,
		onSuccess: data => {
			queryClient.setQueryData(['fileId'], data.data)
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useFileQuery = () => {
	const client = useQueryClient()
	return client.getQueryData<FileResponse>(['file'])
}
