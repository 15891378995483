import {createTheme} from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: ['Circular', 'sans-serif'].join(','),
		fontSize: 12,
		body1: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 400,
		},
		body2: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '12px',
			lineHeight: '17px',
			fontWeight: 600,
		},
		subtitle2: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '14px',
			lineHeight: '19px',
			fontWeight: 700,
		},
		subtitle1: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '12px',
			lineHeight: '17px',
			fontWeight: 400,
		},
		h2: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '56px',
			lineHeight: '78px',
			fontWeight: 800,
		},
		h3: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '48px',
			lineHeight: '62px',
			fontWeight: 800,
			marginBottom: '3rem'
		},
		h4: {
			color: '#151618',
			fontFamily: 'Circular',
			fontSize: '20px',
			lineHeight: '32px',
			fontWeight: 700,
			marginBottom: '1rem'
		}
	},
	shape: {
		borderRadius: 4
	},
	palette: {
		text: {
			primary: "#151618",
			secondary: '#0370F3',
		},
		primary: {
			main: "#151618",
		},
		secondary: {
			main: "#F5F8F9",
		},
		greyBlue: {
			main: '#CDD8DE',
			secondary: '#EDF2F5',
			primary: '#EDECEC',
		},
		darkGrey: {
			main: '#4A4A4A',
			primary: '#8C9DA7',
		},
		green: {
			main: "#151618"
		},
		darkBlue: {
			main: "#025AC4",
			secondary: "#95C2F9",
		},
		orange: {
			main: '#0370F3'
		},
		gradient: {
			main: 'linear-gradient(to right bottom, #0370F3, #0370F3)',
			secondary: 'linear-gradient(to right, #0370F3, #0370F3)'
		}
	},
	custom: {
		wrapper: {
			padding: "24px",
			backgroundColor: "#F5F8F9",
			borderRadius: '8px',
			position: 'relative',
			zIndex: '1',
		}
	},
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: '10px 10px 10px 0',
					"&:hover": {
						background: 'transparent'
					}
				},
				colorPrimary: {
					color: '#DDE5EA',
					'&:checked': {
						color: 'green',
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#8C9DA7',
					fontSize: '14px',
					"&.Mui-focused": {
						color: '#8C9DA7',
						fontSize: '14px',
					},
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					"& .MuiOutlinedInput-notchedOutline": {
						border: '1px solid #CDD8DE',
						borderRadius: '8px',
						fontSize: '14px',
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: '1px solid #B1BCC2 !important',
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "#B1BCC2 !important"
					},
					'&:after, &:before' :{
						display: 'none'
					},
				},
				input: {
					backgroundColor: '#fff',
					borderRadius: '8px',
					color: '#151618',
					fontFamily: 'Circular',
					fontSize: '12px',
					fontWeight: 400,
				},
			}
		}
	}
});
