import { createContext, Dispatch, SetStateAction } from 'react';
import { IFormInput } from "../ui/FormDataProvider";
import { Plans } from "shared/config/enums/formEnums";
import { mockPayload } from 'shared/api/assurant/quote';
import { QuotePayload, SchemaAddress } from 'shared/api/assurant/quotePayloadTypes';
import { QuoteResponse } from 'shared/api/assurant/quoteResponseTypes';
import { IFormICheckoutInput } from 'shared/config/types/checkout';

export interface Plan {
	period: Plans,
	installmentAmount: string | null,
	downPaymentAmount:  string,
	fee: string | null
}
interface FormDataContextProps {
	refInitialData: boolean;
	formData: IFormInput,
	isPending: boolean,
	initialPayload: QuotePayload,
	setFormData: Dispatch<SetStateAction<IFormInput>>,
	protections: Protections,
	setProtections: Dispatch<SetStateAction<Protections>>
	setInitialPayloadData: Dispatch<SetStateAction<QuotePayload>>,
	query?:  QuoteResponse
	defaultQuery?:  QuoteResponse
	setDefaultQuery:  Dispatch<SetStateAction<QuoteResponse | undefined>>
	policyStartDate: string
	setPolicyStartDate: Dispatch<SetStateAction<string>>
	policyState: string
	setPolicyState: Dispatch<SetStateAction<string>>
	schemaAddress: SchemaAddress
	setSchemaAddress: Dispatch<SetStateAction<SchemaAddress>>
	formId: string
	plan: Plan,
	setPlan: Dispatch<SetStateAction<Plan>>
	setFormId: Dispatch<SetStateAction<string>>
	paymentData: IFormICheckoutInput;
	setPaymentData: Dispatch<SetStateAction<IFormICheckoutInput>>
}
export type Protections = Record<string, string | number[] | number>
export const FormDataContext = createContext<FormDataContextProps>({
	refInitialData: true,
	formId: '',
	isPending: false,
	setFormId: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setPlan: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	plan: {
		period: Plans.ANNUAL,
		installmentAmount: '',
		downPaymentAmount:  '',
		fee: ''
	},
	schemaAddress: {
		City: 'Anytown',
		State: 'TX',
		FullAddress: '123 Main Street, TX, Anytown 73301',
		Address1: '123 Main Street',
		Address2: '123 Main Street',
		ZipCode: '73301',
	},
	policyState: 'FL',
	policyStartDate: '2023-12-21',
	initialPayload: mockPayload,
	formData: {
		plan: {
			period: Plans.ANNUAL,
			price: '',
			installmentAmount: '',
			fee: ''
		},
		liability: '',
		deductible: '',
		belongings: 5000
	},
	setFormData: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setSchemaAddress: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setPolicyState: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setPolicyStartDate: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setDefaultQuery: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	protections: {
		replacementCost: 'Y',
		earthquake: 'N',
		identityFraud: 'Y',
		waterBackup: 'Y',
	},
	setProtections: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	setInitialPayloadData: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
	paymentData: {
		firstName: '',
		lastName: '',
		fullName: '',
		fullAddress: '',
		phoneNumber: '',
		dateOfBirth: '',
		email: '',
		alerts: false,
		policy: false,
		communityName: '',
		propertyRadio: 'sameAddress',
		billingAddressOption: 'useProperty',
		paymentType: 'cc',
		payment: {
			cardName: '',
			cardNumber: '',
			cardDate: '',
			cardCode: '',
			bankAccountNumber: '',
			bankAccountNumConfirm: '',
			bankRoutingNumber: ''
		},
		billingAddress: {
			city: '',
			state: '',
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			zip: ''
		},
		mailingAddress: {
			city: '',
			state: '',
			address1: '',
			address2: '',
			postalCode: ''
		},
		billingDate: new Date,
		additionalInsured: {
			firstName: '',
			lastName: ''
		},
		interestedParty: {
			name: '',
			streetAddress: '',
			app: '',
			city: '',
			state: '',
			zipcode: ''
		},
		authUser: {
			name: '',
			streetAddress: '',
			app: '',
			city: '',
			state: '',
			zipcode: ''
		},
		authorizedUser: {
			authorizedUserFirstName: '',
			authorizedUserLastName: ''
		},
		mailingSave: '',
		billingSave: '',
		roommates: [],
	},
	setPaymentData: () => {
		throw new Error(
			'FormDataContext must be inside a Provider with a value'
		)
	},
});
