/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import {
	ApplicationFormResponse,
	ApplicationProcessResponse,
	DynamicSchemaResponse,
	UpdateApplicationFormRequest
} from './types';
import { MicroSiteApiPrivate } from './api'
import { FileResponse } from './assurant/policy/types';
import Auth from './assurant/api';

// eslint-disable-next-line max-len
const token = `0oa1x6yw8pfqwHWEv0h8:xB9QIoasywWbn__5gcoRvW1b6JWOWnLfEA6SmKJ2zY6eRFFTT7HM1kP9r6_a-vbd`;
const encodedToken = Buffer.from(token).toString('base64');

const $authApi = axios.create({
	baseURL: 'https://movedapi-dev.rein.ai/IXProduct/api/RequestProductOptions',
})
const $baseApi = axios.create({
	baseURL: 'https://housing-apis-model.assurant.com/api/globalhousing/renters/quote/v2/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
	},
})
const $api = axios.create({
	baseURL: 'https://movedapi-dev.rein.ai/IXCustomer/api/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
	},
})
const $disclosureApi = axios.create({
	baseURL: 'https://movedapi-dev.rein.ai/IXProduct/api/ProductDisclosures',
	headers: {
		accept: 'application/json; charset=UTF-8',
	},
})
const $policyApi = axios.create({
	baseURL: 'https://housing-apis-model.assurant.com/api/globalhousing/renters/policy/v2/',
	headers: {
		accept: 'application/json; charset=UTF-8',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
		'Cache-Control': 'no-cache',
		'Access-Control-Allow-Origin': '*',
	},
})
const $uwquestionsApi = axios.create({
	baseURL: '',
	headers: {
		accept: 'application/json',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': '40849a93a48d43dc9c129bf26cca3059',
	},
})

export {
	$authApi,
	$baseApi,
	$policyApi,
	$disclosureApi,
	$uwquestionsApi,
	$api
}

$api.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

let failedQueue: any[] = [];
let isRefreshing = false;

const setAuthDataStored = (authData: string) => {
	localStorage.setItem('assurantJwt', authData);
};
const processQueue = (error: any) => {
	failedQueue.forEach((p) => {
		if (error) {
			p.reject(error);
		} else {
			p.resolve();
		}
	});

	failedQueue = [];
};

const logout = () => {
	localStorage.removeItem('assurantJwt');
};
$baseApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);
$baseApi.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		originalRequest.headers = JSON.parse(
			JSON.stringify(originalRequest.headers || {})
		);

		// If error, process all the requests in the queue and logout the user.
		const handleError = (error: any) => {
			processQueue(error);
			logout();
			return Promise.reject(error);
		};
		// Refresh token conditions
		if (
			(error.response?.status === 401 ||
				error.response?.status === 403) &&
			originalRequest?.url !== '/connect/token' &&
			!originalRequest?.url.includes('assurant') &&
			originalRequest?._retry !== true
		) {
			if (isRefreshing) {
				return new Promise(function (resolve, reject) {
					failedQueue.push({resolve, reject});
				})
					.then(() => {
						return $baseApi(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}
			isRefreshing = true;
			originalRequest._retry = true;
			const applicationFormId = localStorage.getItem("applicationFormId");

			return Auth.getAccessToken({productRequest: [], productCompanyId: '', productId: '', customerJourneyId: applicationFormId!})
				.then((authData) => {
					setAuthDataStored(authData?.data.productCompanyId);
					processQueue(null);

					return $baseApi(originalRequest);
				}, handleError)
				.finally(() => {
					isRefreshing = false;
				});
		}

		if (
			error.response?.status === 401 ||
			error.response?.status === 403
		) {
			return handleError(error);
		}
		return Promise.reject(error);
	}
);
$policyApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

$disclosureApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

$uwquestionsApi.interceptors.request.use(
	(config) => {
		if (config.headers) {
			const access_token = localStorage.getItem('assurantJwt')
			if (access_token && config.headers) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

export default class ApplicationForm {
	static async getData(applicationFormId: string): Promise<AxiosResponse<DynamicSchemaResponse>> {
		return MicroSiteApiPrivate.get(
			`/ApplicationForms/${applicationFormId}/DynamicSchema`
		);
	}
	static async updateData({ applicationFormId, data }: UpdateApplicationFormRequest): Promise<AxiosResponse<ApplicationFormResponse>> {
		return MicroSiteApiPrivate.patch(
			`/ApplicationForms/${applicationFormId}`, data
		);
	}
	static async getApplicationProcess(applicationFormId: string): Promise<AxiosResponse<ApplicationProcessResponse>> {
		return MicroSiteApiPrivate.get(
			`/ApplicationForms/${applicationFormId}/applicationprocess`
		);
	}
	static async applicationProcessBack(applicationFormId: string): Promise<AxiosResponse<ApplicationProcessResponse>> {
		return MicroSiteApiPrivate.post(
			`/ApplicationForms/${applicationFormId}/applicationprocess/back`
		);
	}
	static async createFile({referenceId, formData}: {referenceId: string, formData: FormData}): Promise<AxiosResponse<{ id: string }>> {
		return MicroSiteApiPrivate.post(
			`/Files?ReferenceId=${referenceId}&ReferenceType=PolicyDocument`, formData, {headers: { "Content-Type": "multipart/form-data" },}
		);
	}
	static async getFile(id: string): Promise<AxiosResponse<FileResponse>> {
		return MicroSiteApiPrivate.get<FileResponse>(
			`/Files/${id}`,
		);
	}
}

// Address Autocomplete
export const herePlatformHeader = {
	baseURL: 'https://autocomplete.search.hereapi.com/v1/',
};

export const IXHerePlatform = axios.create(herePlatformHeader);

export const smartyStreetsAutocomplete = (query: string) => {
	return axios.get(`https://us-autocomplete-pro.api.smarty.com/lookup?key=11982700850757264&search=${query}`);
};
export const locationAutocomplete = (textBox: string) => {
	const params: string = '?' +
		'q=' + encodeURIComponent(textBox) +
		`&in=${encodeURIComponent('countryCode:USA')}` +
		'&apikey=bAxh9MfktfDXp7z-92Vxj4Z47bs0eNbfRR4XUg0ncTg';
	return IXHerePlatform.get('autocomplete' + params);
};
