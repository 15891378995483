import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import CoverageInfo from 'shared/ui/CoverageInfo/CoverageInfo';
import { SidebarUI } from './styles';
import { useMediaQuery } from '@mui/material';
import Loader from 'shared/ui/Loader/Loader';

interface Props {
	refContainer?: MutableRefObject<HTMLDivElement | null>
}

export const Sidebar = ({ refContainer }: Props) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [sticky, setSticky] = useState(false);
	const matchesSM = useMediaQuery('(max-width:900px)');
	const { isPending } = useContext(FormDataContext);
	useEffect(() => {
		const handleScroll = () => {
			if (refContainer?.current && ref.current && window.scrollY - 70 > refContainer?.current?.getBoundingClientRect().top) {
				setSticky(true)
			} else {
				setSticky(false)
			}
			if (refContainer?.current && window.scrollY <= refContainer?.current?.offsetTop - 30) {
				setSticky(false)
			}
		};

		!matchesSM && window.addEventListener('scroll', handleScroll);

		return () => {
			!matchesSM && window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<SidebarUI sm={matchesSM} ref={ref} sticky={sticky}>
			{isPending && <Loader color={'rgba(255, 255, 255, 0.5)'}/>}
			<CoverageInfo/>
		</SidebarUI>
	)
}
