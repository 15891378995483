import {API_PATH, API_VERSION} from "../utils/envConfig";

export const defaultHeader = {
	baseURL: `${API_PATH}/api/${API_VERSION}`,
	headers: {
		['Accept-Language']: 'en_US',
		['Content-Type']: 'application/json',
	},
};

export const defaultHeaderPublic = {
	baseURL: API_PATH,
	headers: {
		['Accept-Language']: 'en_US',
		['Content-Type']: 'application/x-www-form-urlencoded',
	},
};
