/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { ReactNode, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { FormDataContext, Plan, Protections } from "../lib/FormDataContext";
import { Plans } from "shared/config/enums/formEnums";
import { Parameter, QuotePayload } from 'shared/api/assurant/quotePayloadTypes';
import { mockPayload } from 'shared/api/assurant/quote';
import { useMutationQuery, useQuoteQuery } from 'entities/Quote/hooks/queries';
import { QuoteResponse } from 'shared/api/assurant/quoteResponseTypes';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { getDwellingType } from 'shared/utils/helpers';
import { Question } from '../../../../shared/config/types/customerResponse';

export interface IFormInput {
	plan: {
		period: Plans,
		price: string,
		installmentAmount: string,
		fee: string | null
	},
	liability: string | undefined | null
	belongings: number
	deductible: string
}

interface Props {
	children?: ReactNode
}
const floodIncludedStates = ['CT', 'NJ', 'NY', 'TX']
const FormDataProvider = ({ children }: Props) => {
	const ref = useRef<boolean>(false);
	const refInitialData = useRef<boolean>(true);
	const { mutate, data, isPending } = useMutationQuery()
	const [plan, setPlan] = useState<Plan>({
		period: Plans.ANNUAL,
		installmentAmount: '',
		downPaymentAmount:  '',
		fee: ''
	});
	const query = useQuoteQuery()
	const { applicationFormData } = useContext(ApplicationFormContext);
	const [defaultQuery, setDefaultQuery] = useState<QuoteResponse | undefined>(undefined)
	const [policyStartDate, setPolicyStartDate] = useState('2023-12-22')
	const [policyState, setPolicyState] = useState('FL')
	const [formId, setFormId] = useState('')
	const [schemaAddress, setSchemaAddress] = useState({
		City: '',
		State: '',
		FullAddress: '',
		Address1: '',
		Address2: '',
		ZipCode: '',
	})
	const [formData, setFormData] = useState<IFormInput>({
		plan: {
			period: Plans.ANNUAL,
			price: '',
			installmentAmount: '',
			fee: '',
		},
		liability: '',
		deductible: '',
		belongings: 5000
	});
	const [protections, setProtections] = useState<Protections>({
		hasReplacementCost: 'Y',
		hasEarthquake: 'N',
		hasIdFraud: 'Y',
		hasWaterBackup: 'Y',
		hasWaterDamageLiability: 'Y',
	});
	const [initialPayloadData, setInitialPayloadData] = useState<QuotePayload>(mockPayload);
	const [paymentData, setPaymentData] = useState<any>();

	const defaultProps = useMemo(() => ({
		isPending,
		formData,
		setFormData,
		protections,
		setProtections,
		initialPayload: initialPayloadData,
		setInitialPayloadData,
		query,
		defaultQuery,
		setDefaultQuery,
		policyStartDate,
		plan,
		setPlan,
		setPolicyStartDate,
		policyState,
		setPolicyState,
		schemaAddress,
		setSchemaAddress,
		formId,
		setFormId,
		paymentData,
		setPaymentData,
		refInitialData: refInitialData.current,
	}), [formData, setFormData, protections, setProtections, initialPayloadData, setInitialPayloadData, query, defaultQuery, policyStartDate,
		setPolicyStartDate, policyState, setPolicyState, schemaAddress, setSchemaAddress, formId, setFormId, isPending, plan,
		setPlan, paymentData, setPaymentData]);

	useEffect(() => {
		if (data?.data) {
			setDefaultQuery(data.data);
			const currentProperty = initialPayloadData.coverage.parameters.find((item) => item.name === 'personalPropertyAmount');
			const waterBackup = initialPayloadData.coverage.parameters.find((item) => item.name === 'waterBackup');
			const belongingChosen = data.data.coverageDetails.parameters.find((item) => item.name === 'personalPropertyAmount');
			const waterBackupChosen = data.data.coverageDetails.parameters.find((item) => item.name === 'waterBackup');

			if (
				(belongingChosen?.value && currentProperty?.value !== belongingChosen.value) ||
				(waterBackupChosen?.value && waterBackup?.value !== waterBackupChosen.value)
			) {
				const newParametersData = initialPayloadData.coverage.parameters.map((item) => {
					if(item.name === 'personalPropertyAmount' && belongingChosen) {
						return {...item, value: String(belongingChosen.value)}
					}
					if(item.name === 'waterBackup' && waterBackupChosen) {
						return {...item, value: String(waterBackupChosen.value)}
					}
					return item
				})
				setInitialPayloadData({
					...initialPayloadData,
					coverage: {
						parameters: newParametersData
					}
				})
			}
		}
	}, [data, setDefaultQuery]);
	useEffect(() => {
		if (ref.current && applicationFormData && initialPayloadData.transaction.clientId) {
			mutate({
				...initialPayloadData,
				transaction: {
					...initialPayloadData.transaction,
					workItemId: initialPayloadData.transaction.workItemId,
					"referralId": initialPayloadData?.transaction.referralId,
					policyEffectiveDate: policyStartDate
				},
				policyHolder: {
					...initialPayloadData.policyHolder,
					insured: {
						address: {
							...initialPayloadData.policyHolder.insured.address,
							address1: schemaAddress.Address1,
							address2: schemaAddress.Address2,
							city: schemaAddress.City,
							state: schemaAddress.State,
							postalCode: schemaAddress.ZipCode,
						}
					},
				},
				underWriting: {
					questions: [
						...initialPayloadData.underWriting.questions,
					]
				}
			})
		}
		ref.current = true
	}, [initialPayloadData, mutate, policyStartDate, schemaAddress])
	useEffect(() => {
		if(refInitialData.current && applicationFormData) {
			const underwritingData = applicationFormData.underwriting.questions.reduce<Record<string, string>>((accum, item) => {
				accum[item.questionName] = item.answer
				return accum
			}, {})
			let params = applicationFormData.coverage_info.parameters as Parameter[]
			if(applicationFormData.property_address.state === 'NC') {
				params = [...params, { name: 'hasWaterDamageLiability', value: 'Y' } as Parameter]
			}
			if(floodIncludedStates.includes(applicationFormData.property_address.state)) {
				params = [...params, { name: 'floodIncluded', value: 'Y' } as Parameter]
			}
			const newParams = params.reduce<Record<string, string | number>>((accum, item) => {
				accum[item.name] = item.value
				return accum
			}, {})
			setProtections(newParams)
			const newPayload = {
				coverage: {
					parameters: params,
				},
				transaction: {
					productType: applicationFormData.quote_result.transactionDetails.productId,
					clientId: applicationFormData.quote_result.transactionDetails.clientId,
					workItemId: applicationFormData.quote_result.transactionDetails.workItemId,
					jobNumber: "000932",
					referralId: applicationFormData.quote_result.transactionDetails.referralId,
					policyEffectiveDate: policyStartDate
				},
				policyHolder: {
					insured: {
						address: {
							address1: schemaAddress.Address1,
							address2: schemaAddress.Address2,
							city: schemaAddress.City,
							state: schemaAddress.State,
							postalCode: schemaAddress.ZipCode,
						}
					},
				},
				underWriting: {
					questions: [
						...applicationFormData.underwriting.questions,
						{
							"questionName": "dwellingType",
							"answer": underwritingData?.dwellingType
						},
						{
							"questionName": "howManyLosses",
							"answer": underwritingData?.howManyLosses
						},
						{
							"questionName": "lastLossDate",
							"answer": underwritingData?.lastLossDate
						},
						{
							"questionName": "isManufacturedHome",
							"answer": underwritingData?.isManufacturedHome
						},
					]
				}
			}
			// mutate(newPayload)
			setInitialPayloadData(
				{
					...newPayload,
					transaction: {...newPayload.transaction, workItemId: applicationFormData?.quote_result.transactionDetails.workItemId}
				});
			refInitialData.current = false
		}
	}, [applicationFormData]);


	return (
		<FormDataContext.Provider value={defaultProps}>
			{children}
		</FormDataContext.Provider>
	)
}

export default FormDataProvider
