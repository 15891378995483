/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import RadioButton from "shared/ui/RadioButton/RadioButton";
import { numberToCurrency } from 'shared/utils/NumberToCurrency';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { useResolutionQuery } from 'entities/Resolution/hooks/queries';
import { CoveragesCueParameter } from 'shared/api/assurant/quoteResponseTypes';


const Liability = () => {
	const { control, setValue, getValues } = useFormContext()
	const {
		setInitialPayloadData,
		defaultQuery,
		initialPayload
	} = useContext(FormDataContext);

	const resolutionQuery = useResolutionQuery();

	const [liabilityOptions, setLiabilityOptions] = useState<CoveragesCueParameter | undefined>();
	const liabilityChosen = initialPayload.coverage.parameters.find((item) => item.name === 'liabilityAmount');

	useEffect(() => {
		if (defaultQuery) {
			const liability_Options: any = defaultQuery.coveragesCue.parameters.find((item) => item.name === 'personalLiabilityOptions');
			const result = liability_Options?.value ? liability_Options?.value.filter((option: number) => option !== 50000) : [];
			setLiabilityOptions({
				name: "personalLiabilityOptions",
				value: result,
			});
		}
	}, [defaultQuery]);

	useEffect(() => {
		const liability_Options = liabilityOptions?.value;
		if (
			(resolutionQuery?.outcomeName === 'Denied') &&
			liability_Options && Array.isArray(liability_Options)
		) {
			const result = liability_Options.filter((option) => option !== 300000);
			setLiabilityOptions({
				name: "personalLiabilityOptions",
				value: result,
			})
		}
	}, [resolutionQuery]);

	if (getValues('liability') !== liabilityChosen?.value) {
		setValue('liability', liabilityChosen?.value);
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
		setValue('liability', value)
		setInitialPayloadData((prevState) => {
			if(!prevState) return prevState;
			const newParametersData = prevState.coverage.parameters.map((item) => {
				if (item.name === 'liabilityAmount') {
					return { ...item, value }
				}
				return item
			})
			return {
				...prevState,
				coverage: {
					parameters: newParametersData
				}
			}
		})
	}

	return (
		<Box sx={{ mb: 4 }}>
			<Box sx={{ mb: 3 }}>
				<Typography variant="subtitle2" sx={{ mb: 2 }}>Your Liability</Typography>
				<Typography>This protects you in case
					you cause damage to your home or neighboring property because of smoke, fire, water and explosion.
					And, if a guest gets hurt or bitten by your dog, we'll cover necessary medical and legal expenses up
					to the amount you choose below.</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item md={12} sm={12} xs={12}>
					{liabilityOptions && <Controller
						name="liability"
						control={control}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									onChange={handleChange}
									aria-label="icon-radio"
								>
									<Grid container spacing={3}>
										{/*{liabilityOptions.value && Array.isArray(liabilityOptions.value) && liabilityOptions.value.map((item) => {*/}
										{/*	return (*/}
										<Grid item md={6} sm={6} xs={12}>
											<RadioButton
												checked={true}
												value={field.value}
												label={numberToCurrency(field.value as number)}
											/>
										</Grid>
										{/*)*/}
										{/*})*/}
										{/*}*/}
									</Grid>
								</RadioGroup>
							)
						}}/>
					}
				</Grid>
			</Grid>
		</Box>
	)
}

export default Liability
