import axios from 'axios';
import {defaultHeader, defaultHeaderPublic} from './headers';
import {tokenParams} from './tokenParams';

export const MicroSiteApiPrivate = axios.create(defaultHeader);
export const MicroSiteApiPublic = axios.create(defaultHeaderPublic);

const getAuthDataStored = () => {
	const authData = localStorage.getItem('authorization');
	return authData ? JSON.parse(authData) : {};
};

const setAuthDataStored = (authData) => {
	localStorage.setItem('authorization', authData ? JSON.stringify(authData) : '{}');
};

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error) => {
	failedQueue.forEach((p) => {
		if (error) {
			p.reject(error);
		} else {
			p.resolve();
		}
	});

	failedQueue = [];
};

const logout = () => {
	localStorage.removeItem('authorization');
};


MicroSiteApiPrivate.interceptors.request.use(
	(config) => {
		if (config.authorization !== false) {
			const {access_token, token_type} = getAuthDataStored();
			if (access_token && token_type && config.headers) {
				config.headers.authorization = `${token_type} ${access_token}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

MicroSiteApiPrivate.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		originalRequest.headers = JSON.parse(
			JSON.stringify(originalRequest.headers || {})
		);

		// If error, process all the requests in the queue and logout the user.
		const handleError = (error) => {
			processQueue(error);
			logout();
			return Promise.reject(error);
		};

		// Refresh token conditions
		if (
			(error.response?.status === 401 ||
				error.response?.status === 403) &&
			originalRequest?.url !== '/connect/token' &&
            !originalRequest?.url.includes('assurant') &&
			originalRequest?._retry !== true
		) {
			if (isRefreshing) {
				return new Promise(function (resolve, reject) {
					failedQueue.push({resolve, reject});
				})
					.then(() => {
						return MicroSiteApiPrivate(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}
			isRefreshing = true;
			originalRequest._retry = true;
			if (!tokenParams.clientId) throw new Error('clientId is missing. Make sure you set the clientId property to tokenParams.');

			const urlSearchString = window.location.search;
			const params = new URLSearchParams(urlSearchString);
			const clientId = params.get('client_id');
			const client_id = localStorage.getItem("client_id");
			const scope = params.get('scope') || localStorage.getItem("scope");
			const tokenParamsWithClientId = { ...tokenParams.params, client_id: clientId || client_id, scope }

			return getToken(tokenParamsWithClientId)
				.then((authData) => {
					setAuthDataStored(authData?.data);
					processQueue(null);

					return MicroSiteApiPrivate(originalRequest);
				}, handleError)
				.finally(() => {
					isRefreshing = false;
				});
		}

		if (
			error.response?.status === 401 ||
			error.response?.status === 403
		) {
			return handleError(error);
		}
		return Promise.reject(error);
	}
);

// getToken
export const getToken = (params) => {
	return MicroSiteApiPublic.post('/connect/token', params);
};
