/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const getDefinitionKey = (value: any) => {
	const definitions = value?.$ref;
	return definitions?.replace('#/definitions/','');
};
const getDefinitions = (definition_key: string, requestedSchema: any) => {
	return requestedSchema.dynamicSchema.definitions[definition_key];
};

export const adaptParams = <T extends { name: string, value: null | string }>(schema: T[]) => {
	return schema.reduce<Record<string, string>>((accum, item) => {
		accum[item.name] = item.value ?? ''
		return accum
	}, {})
}
function getUserData(schema: any) {
	if (schema) {
		const initialData: any = {};
		for(const key in schema.dynamicSchema.properties) {
			const definition_key: string = getDefinitionKey(schema.dynamicSchema.properties[key]);
			const definitions: any = getDefinitions(definition_key, schema);
			initialData[key] = definitions;
		}
		return initialData;
	}
}

export default getUserData;
