/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApplicationForm from 'shared/api';
import { DynamicSchemaResponse } from 'shared/api/types';
import { CustomerFormattedType, JourneyResult } from '../../../shared/config/types/customerResponse';
import { useContext } from 'react';
import { FormDataContext } from '../../../app/providers/formValues/lib/FormDataContext';
import Quote, { mockPayload } from '../../../shared/api/assurant/quote';

export const useCustomerJourneyMutationQuery = () => {
	const queryClient = useQueryClient()
	const { setInitialPayloadData } = useContext(FormDataContext);
	return useMutation({
		mutationFn: Quote.getRentersQuote,
		onSuccess: data => {
			const journeyResult: JourneyResult = parse(data.data.journeyResult)
			queryClient.setQueryData<CustomerFormattedType>
			(['applicationFormData'], { ...data.data,
				journeyResult: journeyResult  }
			);
			queryClient.setQueryData<JourneyResult>
			(['journeyData'],
				{
					...journeyResult  }
			);
		},
		onError: error => {
			console.log('Error getting quote', error);
			setInitialPayloadData(mockPayload)
		}
	})
}

export const useCustomerDataUpdateMutationQuery = () => {
	return useMutation({
		mutationFn: Quote.updateRentersQuote,
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useJourneyResultFormQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<JourneyResult>(['journeyData']);
}
function parse(str: string): JourneyResult {
	return JSON.parse(str) as unknown as JourneyResult
}
export const useJourneyQuery = () => {
	const client = useQueryClient()
	return client.getQueryData<CustomerFormattedType>(['applicationFormData'])
}
