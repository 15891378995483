import { $authApi } from '../index';
import { AxiosResponse } from 'axios';

export type AuthPayload = {
	customerJourneyId: string,
	productId: string,
	productCompanyId: string,
	productRequest: string[]
}
export default class Auth {
	static async getAccessToken(payload: AuthPayload): Promise<AxiosResponse<AuthPayload>> {
		return $authApi.post(``, payload)
	}
}
