/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useEffect, useRef, useState } from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import { StyledButton } from "shared/ui/Button/styles";
import { StatesArray } from 'shared/utils/statesHash';
import { AutocompleteField } from 'shared/ui/Fields/AutocompleteField';
import ModalWindow from 'shared/ui/ModalWindow/ModalWindow';
import { locationAutocomplete, smartyStreetsAutocomplete } from 'shared/api';
import { useDebounce } from 'shared/utils/useDebounce';
import { Controller, useForm, useFormContext } from "react-hook-form";
import { AddressDataProps, AddressProps } from '../CheckoutForm/components/types';
import { DropDownMenu, DropDownMenuElement } from '../CheckoutForm/components/styles';
import { is_usZipCode } from '../../shared/utils/validations/zipCode';


interface Props {
	open: boolean,
	handleClose: () => void
	formType?: 'interestedParty' | 'authUser'
}

interface Party {
	name: string,
	address1: string,
	address2: string,
	city: string,
	state: string,
	zip: string,
}

const defaultValues = {
	name: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
};

const InterestedParty = ({ open, handleClose, formType = 'interestedParty'}: Props) => {
	const { setValue, getValues } = useFormContext();
	const [addressData, setAddressData] = useState<AddressDataProps[] | null>(null);
	const [isElementFocused, setIsElementFocused] = useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const inputRef: any = useRef(null);
	const dropdownBlock: any = useRef(null);

	const { control, handleSubmit, setValue: setCurrentForm, watch, trigger } = useForm<Party>({
		defaultValues: { ...defaultValues },
		mode: 'all',
	});
	const billingAddressWatcher = watch('address1');

	const debouncedValue = useDebounce(billingAddressWatcher, 500);

	useEffect(() => {
		const name = getValues(`${formType}.name`);
		const address1 = getValues(`${formType}.streetAddress`);
		const address2 = getValues(`${formType}.app`);
		const city = getValues(`${formType}.city`);
		const state = getValues(`${formType}.state`);
		const zip = getValues(`${formType}.zipcode`);

		if (name) {
			setCurrentForm('name', name);
			setCurrentForm('address1', address1);
			setCurrentForm('address2', address2);
			setCurrentForm('city', city);
			setCurrentForm('state', state);
			setCurrentForm('zip', zip);
		}
	}, [])

	const onSubmit = () => {
		void handleSubmit((data: Party) => {
			setValue(`${formType}.name`, data.name);
			setValue(`${formType}.streetAddress`, data.address1);
			setValue(`${formType}.app`, data.address2);
			setValue(`${formType}.city`, data.city);
			setValue(`${formType}.state`, data.state);
			setValue(`${formType}.zipcode`, data.zip);
			handleClose();
		})()
	}
	useEffect(() => {
		const getAutocompleteResult = async () => {
			try {
				const response = await locationAutocomplete(debouncedValue);
				if (response.data.items.length > 0) {
					setAddressData(response.data.items);
				} else {
					const responseSuggestions = await smartyStreetsAutocomplete(debouncedValue);
					const formattedData: AddressDataProps[] = responseSuggestions?.data?.suggestions?.map((item: any) => ({
						address: {
							city: item?.city,
							countryCode: '',
							countryName: '',
							county: '',
							houseNumber: '',
							label: item?.street_line,
							postalCode: item?.zipcode,
							state: '',
							stateCode: item?.state,
							street: item?.street_line
						}
					}));
					setAddressData(formattedData);
				}

			} catch (e) {
				setAddressData(null);
			}
		};
		if (debouncedValue) getAutocompleteResult().then(r => r);
	}, [debouncedValue]);

	const handleFocusChange = (event: MouseEvent) => {
		if (dropdownBlock.current && isElementFocused && !dropdownBlock.current?.contains(event.target)) {
			setIsElementFocused(false);
		}
		if (inputRef.current && !isElementFocused && inputRef.current?.contains(event.target)) {
			setIsElementFocused(true);
		}
	};
	const handleAddress = (data: AddressProps) => () => {
		const address1 = data.houseNumber ? `${data.houseNumber} ${data.street}` : data.street;
		setCurrentForm("address1", address1);
		setCurrentForm("city", data.city);
		setCurrentForm("state", data.stateCode);
		setCurrentForm("zip", data.postalCode.split('-').shift() ?? '');

		setIsElementFocused(false);
	};

	useEffect(() => {
		addEventListener('mousedown', handleFocusChange, false);
		return () => {
			removeEventListener('mousedown', handleFocusChange, false);
		};
	}, [isElementFocused]);
	const handleAutocompleteChange = (event: React.SyntheticEvent, newValue: {label: string, id: string} | null) => {
		setCurrentForm('state', newValue?.id ?? '');
		trigger('state')
	}
	return (
		<ModalWindow open={open} handleClose={handleClose} header={<Typography variant={"h4"} mb={0}>Add Your Interested Party</Typography>}>
			<Grid container item spacing={2}>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="name"
						control={control}
						rules={{
							required: {value: true, message: "Community/Landlord name is required"},
							maxLength: {value: 30, message: "Community/Landlord name has to be max 30 chars."}
						}}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Name"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12} ref={dropdownBlock} sx={{ position: 'relative' }}>
					<Controller
						name="address1"
						control={control}
						rules={{
							required: {value: true, message: "Street Address is required"},
							maxLength: {value: 30, message: "Street Address has to be max 30 chars."}
						}}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Street address"
								error={!!error}
								helperText={error && error.message}
								ref={inputRef}
							/>
						}
					/>
					{isElementFocused && addressData && addressData?.length > 0 && (
						<DropDownMenu>{addressData?.map((item: any) => {
							return	<DropDownMenuElement
								key={item.address?.label}
								onClick={handleAddress(item.address)}>
								{item.address?.label}
							</DropDownMenuElement>;
						})}</DropDownMenu>
					)}
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="address2"
						control={control}
						rules={{
							required: false,
							maxLength: {value: 30, message: "Apt/Suite has to be max 30 chars."}
						}}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Apt/Suite"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="city"
						control={control}
						rules={{
							required: {value: true, message: "City is required"},
							maxLength: {value: 27, message: "City has to be max 27 chars."}
						}}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								disabled
								fullWidth
								variant="outlined"
								label="City"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="state"
						control={control}
						rules={{
							required: {value: true, message: "State is required"},
							maxLength: {value: 30, message: "State has to be max 30 chars."},
						}}
						render={({ field, fieldState: { error } }) => {
							const {value} = field
							const dataValue = StatesArray.find((item) => item.id === value)
							return <AutocompleteField
								value={dataValue}
								disabled
								inputValue={dataValue ? `${dataValue.label} - ${dataValue.id}` : inputValue}
								onChange={handleAutocompleteChange}
								onInputChange={(event, newInputValue) => {
									setInputValue(newInputValue);
								}}
								getOptionLabel={option => `${option.label} - ${option.id}`}
								error={error}
							/>
						}}
					/>
				</Grid>
				<Grid item md={6} sm={12} xs={12}>
					<Controller
						name="zip"
						control={control}
						rules={{
							required: {value: true, message: "Zip code is required"},
							pattern: {value: /^\d{5}(?:[-\s]\d{4})?$/i, message: "Invalid zip code"},
							validate: (value) => is_usZipCode(value) || 'Invalid 2 zip code',
							maxLength: {value: 30, message: "Zip code has to be max 30 chars."}
						}}
						render={({ field, fieldState: { error } }) =>
							<TextField
								{...field}
								fullWidth
								disabled
								variant="outlined"
								label="Zip code"
								error={!!error}
								helperText={error && error.message}
							/>
						}
					/>
				</Grid>
				<Grid container item justifyContent="center" >
					<Grid item md={6} sm={12} xs={12}>
						<StyledButton onClick={onSubmit}>NOTIFY YOUR INTERESTED PARTY</StyledButton>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Typography variant="body1" mt={2}>By adding the interested party,
							I AGREE that the underwriting insurance
							company may provide certain information to a property manager or landlord relating to the purchase of this insurance,
							including name, address, phone number, type and amount of coverage, coverage status,
							term of coverage, and email address if applicable.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</ModalWindow>
	)
}

export default InterestedParty
