import { IFormInput } from '../../app/providers/formValues/ui/FormDataProvider';
import { Protections } from '../../app/providers/formValues/lib/FormDataContext';

export const changeFieldsValue =
	<T extends {
		name: string,
		value: string | null
	}>(arr: T[], protections: Protections, data: IFormInput) => {
		return arr.map((item) => {
			if (item.name === 'identityFraud') {
				return {
					...item,
					value: protections.identityFraud
				}
			}
			if (item.name === 'waterBackup') {
				return {
					...item,
					value: protections.waterBackup
				}
			}
			if (item.name === 'liabilityAmount') {
				return {
					...item,
					value: data.liability as string
				}
			}
			if (item.name === 'personalPropertyAmount') {
				return {
					...item,
					value: data.belongings.toString()
				}
			}
			if (item.name === 'deductible') {
				return {
					...item,
					value: data.deductible
				}
			}
			return item
		});
	}
