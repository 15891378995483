import { useMutation, useQueryClient } from '@tanstack/react-query';
import Auth, { AuthPayload } from 'shared/api/assurant/api';
import { WorkflowResult } from 'shared/api/types';

export const useAuthMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: Auth.getAccessToken,
		onSuccess: data => {
			if(data.data && data.data.productCompanyId) {
				localStorage.setItem('assurantJwt', data.data.productCompanyId)
			}
			queryClient.setQueryData(['assurantToken'], data)
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useAuthQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<AuthPayload>(['assurantToken']);
}
