import React, { useState } from 'react'
import { Controller, useFormContext } from "react-hook-form";
import { Box, Checkbox, Link, Typography } from "@mui/material";
import { CheckboxItem } from "./styles";
import ShortMessageService from './ShortMessageService';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import HTMLModal from 'shared/ui/HTMLModal/HTMLModal';
import { DocumentType } from 'shared/config/enums/documents';

const Questions = () => {
	const [modalServices, setModalServices] = useState<DocumentType | null>(null);
	const [messageService, setMessageService] = useState(false);
	const { control,  } = useFormContext<IFormICheckoutInput>();

	return (
		<Box sx={{ mt: 2, mb: 3 }}>
			<CheckboxItem>
				<Controller
					name="alerts"
					control={control}
					render={({ field }) =>{
						return  <Checkbox {...field} checked={field.value} disableRipple/>
					}}
				/>
				<div>
					<Typography variant="body2">I'd like to receive text alerts on my phone.</Typography>
					<Typography variant="body1">We'll send you texts keeping you up to date on your policy, never sales
						pitches.</Typography>
				</div>
			</CheckboxItem>
			<CheckboxItem>
				<Controller
					name="policy"
					control={control}
					render={({ field }) => <Checkbox {...field} checked={field.value} disableRipple/>}
				/>
				<Typography variant="body2">I'd like to receive all policy and billing documents by email.</Typography>
			</CheckboxItem>
			<Typography variant="subtitle1">
				By clicking "Make Payment" below, I acknowledge that I have read, agreed and understood these
				disclosures:
				<Link
					color="text.secondary"
					underline="hover"
					sx={{ cursor: 'pointer' }}
					onClick={() => setModalServices('ebc')}> Electronic Business Consent</Link> and
				<Link
					color="text.secondary"
					underline="hover"
					sx={{ cursor: 'pointer' }}
					onClick={() => setMessageService(true)}> Short Message Service</Link>.
			</Typography>
			{modalServices && <HTMLModal
				open={!!modalServices}
				handleClose={() => setModalServices(null)}
				type={modalServices}
			/> }

			{messageService &&
				<ShortMessageService
					open={messageService}
					handleClose={() => setMessageService(false)}
				/>}
		</Box>
	)
}

export default Questions
