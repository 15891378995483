import React from 'react'
import { FormControl, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { periodsObj } from 'widgets/DetailsList/DetailsList';
import { Schedule } from 'shared/api/assurant/quoteResponseTypes';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormInput } from 'app/providers/formValues/ui/FormDataProvider';
import { StyledToggleButtonGroup } from './styles';

interface Props {
	data: Schedule[] | undefined
}

const TogglePlans = (props: Props) => {
	const { data } = props
	const { control, watch } = useFormContext<IFormInput>()
	const planWatcher = watch('plan')

	return (
		<Controller
			name="plan"
			control={control}
			render={({ field }) => {
				return (
					<FormControl>
						<StyledToggleButtonGroup
							value={planWatcher.period}
							size="small"
							aria-label="text alignment"
						>
							{
								data && data.map((item) => {
									return <ToggleButton
										key={item.accountPlanCode}
										sx={{color: '#fff'}}
										value={item.numberOfPayments}
										aria-label={item.numberOfPayments.toString()}
										onClick={() => field.onChange({
											period: item.numberOfPayments,
											price: item.downPaymentAmount,
											installmentAmount: item.installmentAmount,
											fee: item.serviceFee
										})}>
										<Typography variant={"subtitle1"}>{periodsObj[item.numberOfPayments]}</Typography>
									</ToggleButton>
								})
							}
						</StyledToggleButtonGroup>
					</FormControl>
				)
			}}/>
	)
}

export default TogglePlans
