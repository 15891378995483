/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { Autocomplete, Box, Button, Fade, Grid, RadioGroup, TextField, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import RadioButton from "shared/ui/RadioButton/RadioButton";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { fullAddress } from 'widgets/CheckoutForm/helper';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { Address } from 'shared/api/assurant/quotePayloadTypes';
import { KeyProperty } from 'shared/config/types/generic';
import { ErrorChip } from '../../CheckoutErrorsInfo/styles';
import { useDebounce } from 'shared/utils/useDebounce';
import { locationAutocomplete, smartyStreetsAutocomplete } from 'shared/api';
import { DropDownMenu, DropDownMenuElement } from './styles';
import { AddressDataProps, AddressProps } from './types';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { StatesArray } from 'shared/utils/statesHash';
import { AutocompleteField } from '../../../shared/ui/Fields';
import { useJourneyResultFormQuery } from 'entities/ApplicationForm/hooks/queries';

const mailingFieldsArray: KeyProperty<Address, 'mailingAddress'>[] = [
	'mailingAddress.address1',
	'mailingAddress.address2',
	'mailingAddress.city',
	'mailingAddress.state',
	'mailingAddress.postalCode'
]

const PropertyAddress = () => {
	const { paymentData } = useContext(FormDataContext);
	const [inputValue, setInputValue] = React.useState('');
	const { applicationAddress, setApplicationAddress } = useContext(ApplicationFormContext);
	const { formState, trigger, watch, resetField, reset, getValues, control, setValue } = useFormContext<IFormICheckoutInput>();
	const { errors } = formState
	const propertyRadio = watch('propertyRadio')
	const [formReset, setFormReset] = useState(false);
	const [showOptionalAddress, setShowOptionalAddress] = useState(false);
	const [mailingAddress, setMailingAddress] = useState("");
	const propertyAddress = getValues("fullAddress");
	const mailingAddressWatcher = useWatch({control, name: 'mailingAddress'});
	const [addressData, setAddressData] = useState<AddressDataProps[] | null>(null);
	const [isElementFocused, setIsElementFocused] = useState(false);
	const dropdownBlock: any = useRef(null);
	const inputRef: any = useRef(null);
	const debouncedValue = useDebounce(mailingAddressWatcher.address1, 500);
	const query = useJourneyResultFormQuery();

	useEffect(() => {
		if (query) {
			setValue('communityName', query.property_info.property_name);
		}
			
	}, [query]);

	useEffect(() => {
		if(paymentData && paymentData.propertyRadio === 'differentAddress') {
			processMailingAddress(paymentData.mailingAddress)
		}
	}, [paymentData]);

	useEffect(() => {
		const getAutocompleteResult = async () => {
			try {
				const response = await locationAutocomplete(debouncedValue);
				if (response.data.items.length > 0) {
					setAddressData(response.data.items);
				} else {
					const responseSuggestions = await smartyStreetsAutocomplete(debouncedValue);
					const formattedData: AddressDataProps[] = responseSuggestions?.data?.suggestions?.map((item: any) => ({
						address: {
							city: item?.city,
							countryCode: '',
							countryName: '',
							county: '',
							houseNumber: '',
							label: item?.street_line,
							postalCode: item?.zipcode,
							state: '',
							stateCode: item?.state,
							street: item?.street_line
						}
					}));
					setAddressData(formattedData);
				}

			} catch (e) {
				setAddressData(null);
			}
		};
		if (debouncedValue) getAutocompleteResult().then(r => r);
	}, [debouncedValue]);

	const handleFocusChange = (event: MouseEvent) => {
		if (dropdownBlock.current && isElementFocused && !dropdownBlock.current?.contains(event.target)) {
			setIsElementFocused(false);
		}
		if (inputRef.current && !isElementFocused && inputRef.current?.contains(event.target)) {
			setIsElementFocused(true);
		}
	};
	useEffect(() => {
		addEventListener('mousedown', handleFocusChange, false);
		return () => {
			removeEventListener('mousedown', handleFocusChange, false);
		};
	}, [isElementFocused]);

	const onSubmit = () => {
		const submit = async () => {
			await trigger(mailingFieldsArray)
			const {errors} = formState
			const {mailingAddress} = getValues()
			if(!errors.mailingAddress) {
				processMailingAddress(mailingAddress);
				setShowOptionalAddress(!showOptionalAddress);
			}
		}
		void submit()
	}
	const fieldsData = useMemo(() => {
		const billingData: string[] = Object.keys(mailingAddressWatcher).filter((item) =>
			item !== 'address2' && mailingAddressWatcher[item as keyof Address] === '' )
		return billingData.length === 0
	}, [mailingAddressWatcher]);


	const processMailingAddress = (data: Address) => {
		const address1 = data.address1;
		const city = data.city;
		const state = data.state;
		const postalCode = data.postalCode;
		const address2 = data.address2;

		setApplicationAddress({
			...applicationAddress,
			mailingAddress: {
				city: city,
				state: state,
				postalCode: postalCode,
				address1: address1,
				address2: address2
			}
		});

		const optionalAddress = fullAddress({address1, address2, city, state, postalCode});
		setMailingAddress(optionalAddress);
	};

	useEffect(() => {
		if (propertyRadio === 'sameAddress' && !formReset) {
			for(const item of mailingFieldsArray) {
				resetField(item)
			}
			if (showOptionalAddress) setShowOptionalAddress(false);
			setMailingAddress("");
			setFormReset(true);
		}
	}, [propertyRadio, reset, formReset, getValues, showOptionalAddress])

	useEffect(() => {
		void trigger('mailingSave')
	}, [mailingAddress])

	useEffect(() => {
		if (propertyRadio === 'differentAddress' && formReset) {
			setFormReset(false);
		}
	}, [propertyRadio, reset, formReset])

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target?.name as keyof IFormICheckoutInput, e.target.value, {shouldValidate: !mailingAddress});
		if (!e.target.value) setAddressData([]);
		void trigger('mailingSave')
	};

	const handleAddress = (data: AddressProps) => () => {
		const address1 = data.houseNumber ? `${data.houseNumber} ${data.street}` : data.street;
		if(data.postalCode) {
			setValue("mailingAddress.address1", address1, {shouldValidate: !mailingAddress});
			setValue("mailingAddress.postalCode", data.postalCode.split('-').shift() ?? '', {shouldValidate: !mailingAddress});
		} else {
			setValue("mailingAddress.address1", '', {shouldValidate: !mailingAddress});
		}
		setValue("mailingAddress.city", data.city, {shouldValidate: !mailingAddress});
		setValue("mailingAddress.state", data.stateCode, {shouldValidate: !mailingAddress});


		setIsElementFocused(false);
	};

	return (
		<Box sx={{ mt: 2, mb: 3 }}>
			<Typography variant="h4">Property address</Typography>
			<Grid container spacing={3}>
				<Grid item md={6} sm={6} xs={12}>
					<Controller
						name="communityName"
						rules={{
							maxLength: { value: 30, message: "Community name has to be max 30 chars." }
						}}
						control={control}
						render={({ field, fieldState: {error} }) =>
							<TextField
								{...field}
								fullWidth
								variant="outlined"
								label="Community name"
								error={!!error}
								helperText={error && error.message}
							/>}
					/>
				</Grid>
			</Grid>
			<Box sx={{ mt: 2, mb: 3 }}>
				<Typography variant="subtitle2" sx={{ mb: 2 }}>Mailing address</Typography>
				<Controller
					name="propertyRadio"
					control={control}
					render={({ field }) => {
						return (
							<RadioGroup
								{...field}
								aria-label="icon-radio"
							>
								<Grid container spacing={3.1}>
									<Grid item md={6} sm={6} xs={12}>
										<RadioButton
											checked={field.value === 'sameAddress'}
											value={'sameAddress'}
											label="Use same address above"
											label2={propertyAddress}
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12}>
										<RadioButton
											checked={field.value === 'differentAddress'}
											value={'differentAddress'}
											label="Use a different mailing address"
											label2={mailingAddress}
											handleEdit={() => setShowOptionalAddress(!showOptionalAddress)}
											showEdit={mailingAddress !== ''}
										/>
									</Grid>
								</Grid>
							</RadioGroup>
						)
					}}
				/>
			</Box>
			<Fade in={propertyRadio === 'differentAddress'} unmountOnExit>
				<Grid container spacing={2}>
					{!showOptionalAddress && (
						<>
							<Grid item md={12} sm={12} xs={12} ref={dropdownBlock} sx={{ position: 'relative' }}>
								<Controller
									name="mailingAddress.address1"
									control={control}
									rules={{
										required: { value: true, message: 'Mailing street address is required' },
										maxLength: { value: 30, message: "Mailing street has to be max 30 chars." }
									}}
									render={({ field, fieldState: { error } }) => {
										return (
											<TextField
												{...field}
												fullWidth
												variant="outlined"
												label="Street address"
												onChange={onInputChange}
												error={!!error}
												helperText={error && error.message}
												ref={inputRef}
											/>
										)
									}
									}
								/>
								{isElementFocused && addressData && addressData?.length > 0 && (
									<DropDownMenu>{addressData?.map((item: any) => {
										return	<DropDownMenuElement
											key={item.address?.label}
											onClick={handleAddress(item.address)}>
											{item.address?.label}
										</DropDownMenuElement>;
									})}</DropDownMenu>
								)}
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="mailingAddress.address2"
									control={control}
									rules={{
										maxLength: {value: 30, message: "Apt/Suite has to be max 30 chars."}
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											variant="outlined"
											label="Apt/Suite"
											error={!!error}
											helperText={error && error.message}
										/>
									}
								/>
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="mailingAddress.city"
									control={control}
									rules={{
										required: { value: true, message: 'Mailing city is required' },
										maxLength: { value: 27, message: "Mailing city has to be max 27 chars." }
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											disabled
											fullWidth
											variant="outlined"
											label="City"
											onChange={onInputChange}
											error={!!error}
											helperText={error && error.message}
										/>
									}
								/>
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="mailingAddress.state"
									control={control}
									rules={{
										required: { value: true, message: 'Mailing state is required' },
										maxLength: { value: 30, message: "Mailing state has to be max 30 chars." }
									}}
									render={({ field, fieldState: { error } }) => {
										const {value} = field
										const dataValue = StatesArray.find((item) => item.id === value)
										return (
											<AutocompleteField
												disabled
												value={dataValue}
												inputValue={dataValue ? `${dataValue.label} - ${dataValue.id}` : inputValue}
												onChange={(event: React.SyntheticEvent, newValue) => {
													setValue('mailingAddress.state', newValue?.id ?? '');
													trigger('mailingAddress.state')
												}}
												onInputChange={(event, newInputValue) => {
													setInputValue(newInputValue);
												}}
												getOptionLabel={option => `${option.label} - ${option.id}`}
												error={error}
											/>
										)
									}
									}
								/>
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Controller
									name="mailingAddress.postalCode"
									control={control}
									rules={{
										required: { value: true, message: 'Mailing Zip code is required' },
										pattern: { value: /^\d{5}(?:[-\s]\d{4})?$/i, message: 'Invalid Zip code' },
										maxLength: { value: 30, message: "Mailing Zip has to be max 30 chars." }
									}}
									render={({ field, fieldState: { error } }) =>
										<TextField
											{...field}
											fullWidth
											disabled
											variant="outlined"
											label="Zip code"
											onChange={onInputChange}
											error={!!error}
											helperText={error && error.message}
										/>
									}
								/>
								{
									propertyRadio === 'differentAddress' && (
										<Box sx={{ display: 'none' }} >
											<Controller
												name="mailingSave"
												control={control}
												rules={{
													required: {
														value: fieldsData && !mailingAddress && !formState.errors.mailingAddress,
														message: 'You must click the “Save Address” button'
													},
												}}
												render={({ field }) =>
													<TextField {...field} fullWidth variant="outlined" label="Apt/Suite" onChange={() => undefined}/>}
											/>
										</Box>
									)
								}
							</Grid>
							<Grid container item md={12}>
								<Grid item md={6} textAlign="left">
									{errors.mailingSave && <ErrorChip>{errors.mailingSave.message}</ErrorChip>}
								</Grid>
								<Grid item md={6} textAlign="right">
									<Button onClick={onSubmit}>
										<Typography
											variant="body1"
											color={"text.secondary"}
											sx={{ mr: 0, cursor: 'pointer' }}>
											Save Address
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Fade>
		</Box>
	)
}

export default PropertyAddress
