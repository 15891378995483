/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { toast, ToastOptions } from "react-toastify";
import {
	TOASTER_OPTIONS,
	TOASTER_TOP_OPTIONS
} from "shared/lib/Toast";
import { ReactElement } from "react";


const showDefaultToast = (
	message: string | ReactElement,
	options?: ToastOptions
) => {
	toast.success(message, {
		...TOASTER_OPTIONS,
		...options,
	});
};

const showInfoMessageToast = (
	message: string | ReactElement,
	options?: ToastOptions
) => {
	toast.success(message, {
		...TOASTER_TOP_OPTIONS,
		...options,
	});
};

const showErrorToast = (
	error: string | ReactElement,
	options?: ToastOptions
) => {
	toast.error(error, {
		...TOASTER_TOP_OPTIONS,
		...options,
	});
};

export const Toaster = {
	success: showDefaultToast,
	error: showErrorToast,
	infoMessage: showInfoMessageToast,
};
