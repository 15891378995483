/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UwQuestions from 'shared/api/assurant/uwquestions';
import { UwQuestionsResponse } from 'shared/api/assurant/uwquestions/types';
import { toast } from 'react-toastify';
import { ErrorList } from 'shared/ui/ErrorList/ErrorList';
import { AxiosError } from 'axios';
import { ErrorDataDetails } from './types';
import { Toaster } from 'shared/ui/Toast/Toast';

export const useUwQuestionsMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: UwQuestions.getQuestions,
		onSuccess: data => {
			queryClient.setQueryData(['uwquestions'], data.data)
		},
		onError: (error: AxiosError<ErrorDataDetails>) => {
			queryClient.setQueryData(['errorDetails'], error.response?.data)
			if(error.response?.data?.errorDetails?.error?.errors) {
				Toaster.error(
					<ErrorList
						list={error.response?.data?.errorDetails?.error?.errors
							.map((item: {code: null | number; description: string; fieldName: string}) => item.description)}
					/>
				)
			} else {
				toast.error('Something went wrong, please try again.')
			}
		}

	})
}
export const useUwQuestionsQuery = () => {
	const client = useQueryClient()
	return client.getQueryData<UwQuestionsResponse>(['uwquestions'])
}
