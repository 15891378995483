import React, { useContext, useEffect } from 'react'
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { useJourneyQuery } from 'entities/ApplicationForm/hooks/queries';
import { useDisclosuresMutationQuery } from 'entities/Disclosures/hooks/queries';
import ModalWindow from '../ModalWindow/ModalWindow';
import { Box, DialogActions, Grid, Typography } from '@mui/material';
import Loader from '../Loader/Loader';
import { Disclosure } from 'widgets/CheckoutForm/components/ElectronicBusinessConsent';
import { StyledButton } from '../Button/styles';
interface HTMLModalProps {
	open: boolean;
	handleClose: () => void;
	type: string
}
const HTMLModal = ({handleClose, type, open}: HTMLModalProps) => {
	const { schemaAddress} = useContext(FormDataContext);
	const journeyResultData = useJourneyQuery();
	const { mutate: getDisclosures, data, isPending, isError  } = useDisclosuresMutationQuery();
	useEffect(() => {
		isError && handleClose()
	}, [isError]);
	useEffect(() => {
		if (open && journeyResultData) {
			getDisclosures({
				customerJourneyId: journeyResultData.customerJourneyId,
				productId: "",
				productCompanyId: "",
				productRequest: [
					{
						"optionName": "State",
						"optionValue": schemaAddress.State
					},
					{
						"optionName": "Type",
						"optionValue": type
					}
				]
			})
		}
	}, [open, getDisclosures])
	const createMarkup = (content?: string) => {
		return {__html: content ?? ''};
	}
	const ebcDataHtml = data ? JSON.parse(data.data.productOptions[0]) as unknown as Disclosure : null
	return (
		<ModalWindow
			open={open}
			handleClose={handleClose}
			header={
				<Typography variant="h4" mb={0}>
					{ebcDataHtml?.documentName ?? "Document Title"}
				</Typography>
			}
		>
			{isPending && <Loader />}
			<Box position="relative" sx={{minHeight: 100, width: '100%'}} display="flex">
				{isError && <div>Something went wrong. Try again later</div>}
				{!isPending && ebcDataHtml?.content &&
					<Typography gutterBottom variant="subtitle1" >
						<div style={{'maxHeight': '90vh'}} dangerouslySetInnerHTML={createMarkup(ebcDataHtml.content)} />
					</Typography>
				}
			</Box>
		</ModalWindow>
	)
}

export default HTMLModal
