/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import DetailBlock from "shared/ui/DetailBlock/DetailBlock";
import { Box, FormControl, Grid, List, ListItem, Typography, useMediaQuery } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import { IFormInput } from "app/providers/formValues/ui/FormDataProvider";
import numeral from 'numeral';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { CardRibbon } from './styles';
import { useQuoteQuery } from '../../entities/Quote/hooks/queries';

export const periodsObj: Record<number, string> = {
	1: 'Annual',
	4: '4-pay',
	11: 'Monthly'
}
const periodsDesc: Record<number, string> = {
	1: 'Pay all at once today to lower your total cost',
	4: '1 down payment today',
	11: '1 down payment today'
}
const plansNumber: Record<number, number> = {
	1: 1,
	4: 3,
	11: 10,
}
const DetailsList = () => {
	const { control, watch, setValue, formState: { isDirty } } = useFormContext<IFormInput>()
	const { formData, setPlan } = useContext(FormDataContext)
	const queryState = useQuoteQuery();
	const planWatcher = watch('plan')
	const matchesSM = useMediaQuery('(max-width:1200px)');
	const formattedNumbers = (
		downPaymentAmount: number | null,
		installmentAmount: number | null,
		serviceFee: number | null,
		numberOfPayments: number
	) => {
		const number = numeral()
		if(queryState?.premiumDetails?.premiumAmount && serviceFee) {
			return  number
				.set(Number(queryState?.premiumDetails?.premiumAmount) + (serviceFee * plansNumber[numberOfPayments]))
				.format('$0,0[.]00')
		}
		return null
	}

	useEffect(() => {
		if (planWatcher.period === 1 && formData.plan.period !== 1 && !isDirty) {
			setValue("plan", formData.plan);
		}
		setPlan({period: planWatcher.period, fee: planWatcher.fee, installmentAmount: planWatcher.installmentAmount, downPaymentAmount: planWatcher.price})
	}, [planWatcher]);

	useEffect(() => {
		const plan = planWatcher.period;
		const item = queryState?.transactionDetails.payments.schedules.find((item) => item.numberOfPayments === plan);

		if (item?.downPaymentAmount) {
			setValue("plan",
				{ period: plan, price: item.downPaymentAmount, fee: item.serviceFee, installmentAmount: item.installmentAmount ?? '' })
		}
	}, [queryState, planWatcher.period, setValue]);
	return (
		<Box sx={{ mt: 3, mb: 4 }}>
			<Controller
				name="plan"
				control={control}
				render={({ field }) => {
					return <FormControl
					>
						<Grid container justifyContent="center" spacing={6}>
							{queryState?.transactionDetails.payments.schedules.map((item) => {
								const number = numeral()
								const downPaymentAmount = numeral(item.downPaymentAmount).value()
								const installmentAmount = numeral(item.installmentAmount).value()
								const serviceFee = numeral(item.serviceFee).value()
								const paymentsResult = formattedNumbers(
									downPaymentAmount,
									installmentAmount,
									serviceFee,
									item.numberOfPayments
								)
								return (
									<Grid
										item
										lg={4}
										md={12}
										sm={12}
										xs={12}
										flexWrap="wrap"
										textAlign="center"
										key={item.accountPlanCode}
										display="flex"
										sx={{ flexDirection: 'column' }}
									>
										<DetailBlock
											active={planWatcher.period === item.numberOfPayments}
											onClick={() => field.onChange({
												period: item.numberOfPayments,
												price: item.downPaymentAmount,
												installmentAmount: item.installmentAmount,
												fee: item.serviceFee
											})}
										>
											{item.numberOfPayments === 1 && <CardRibbon sm={matchesSM}><span>Best Value</span></CardRibbon>}
											<Box display="flex" alignItems="center">
												<Typography variant="subtitle2" sx={{
													color: "inherit",
													fontSize: '12px',
													lineHeight: '16px',
													textTransform: 'uppercase',
													mr: 0.5
												}}>{periodsObj[item.numberOfPayments]}</Typography>
												<Typography variant="h2" sx={{
													color: "inherit",
													fontSize: '24px',
													lineHeight: '31px',
													textTransform: 'uppercase'
												}}>{number.set(item.installmentAmount ?? item.downPaymentAmount).add(item.serviceFee ?? '0').format('$0,0[.]00')}</Typography>
											</Box>
											{paymentsResult && <Typography color="inherit">{paymentsResult}/yr</Typography>}
											<Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
												<List sx={{ width: '100%' }}>
													<ListItem sx={{ justifyContent: 'space-between' }}>
														<Box>
															<Typography sx={{
																color: "inherit",
															}}>
																{periodsDesc[item.numberOfPayments]}
															</Typography>
														</Box>
														<Typography fontWeight={800} textAlign="right" sx={{
															color: "inherit",
														}}>
															{number.set(item.downPaymentAmount).format('$0,0.00')}
														</Typography>
													</ListItem>
													{item.numberOfPayments !== 1 && (
														<ListItem sx={{ justifyContent: 'space-between' }}>
															<Box>
																<Typography sx={{
																	color: "inherit",
																}}>
																	{`Includes ${number.set(item.installmentAmount).format('$0,0.00')} + ${number.set(item.serviceFee).format(	'$0,0.00')} billing fee*`}
																</Typography>
																<Typography fontStyle="italic" sx={{
																	color: "inherit",
																}}>
																	{item.serviceFee &&
																		number.set(item.serviceFee).add(item.installmentAmount).format(	'$0,0.00')
																	}
																</Typography>
															</Box>
															<Typography fontWeight={800} textAlign="right" sx={{
																color: "inherit",
															}}>
																{item.serviceFee &&
																	number.set(item.serviceFee).add(item.installmentAmount).format(	'$0,0.00')
																}
															</Typography>
														</ListItem>
													)}
												</List>
											</Box>
										</DetailBlock>
									</Grid>
								)
							})}
							<Grid item md={12}>
								<Typography variant="subtitle2" fontWeight={400}>
									*Payment plan options are available to all payment methods. If an installment
									payment plan is chosen, an installment fee of $1 to $5 will apply depending on your state and payment method.
								</Typography>
							</Grid>
						</Grid>
					</FormControl>
				}}/>
		</Box>
	)
}

export default DetailsList
