import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Box, Grid, RadioGroup, Typography, useMediaQuery } from "@mui/material";

import DetailsList from "../DetailsList/DetailsList";
import BelongingsSlider from "../BelongingsSlider/BelongingsSlider";
import Liability from "../Liability/Liability";
import Deductible from "../Deductible/Deductible";

import { StyledButton } from "shared/ui/Button/styles";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormInput } from 'app/providers/formValues/ui/FormDataProvider';
import AdditionalProtection from "widgets/AdditionalProtection/AdditionalProtection";
import { Sidebar } from 'features/CoverageSidebar';
import {
	useCustomerDataUpdateMutationQuery,
	useCustomerJourneyMutationQuery,
	useJourneyQuery,
	useJourneyResultFormQuery
} from 'entities/ApplicationForm/hooks/queries';
import { JourneyResult } from 'shared/config/types/customerResponse';
import { changeFieldsValue } from 'shared/utils/changeFieldsValue';
import ModalWindow from '../../shared/ui/ModalWindow/ModalWindow';
import RadioButton from '../../shared/ui/RadioButton/RadioButton';
import { useMutationQuery } from '../../entities/Quote/hooks/queries';

const RentersForm = () => {
	const { setFormData, protections, schemaAddress, initialPayload } = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);


	const { mutate: updateData, isSuccess: fetchSuccess } = useCustomerDataUpdateMutationQuery();
	const { mutate: createNext, isSuccess: quoteSuccess } = useMutationQuery();
	const { mutate: fetchForm } = useCustomerJourneyMutationQuery();
	const journeyData = useJourneyQuery()
	const [questionModal, setQuestionModal] = useState(false);
	const [riQuestionAnswer, setRiQuestionAnswer] = useState('');
	const matchesSM = useMediaQuery('(max-width:900px)');
	const [isPending, setIsPending] = useState(false);
	const query = useJourneyResultFormQuery();

	const urlSearchString = window.location.search;
	const params = new URLSearchParams(urlSearchString);
	const client_id: string = params.get('client_id') || localStorage.getItem('client_id') || "";

	const navigate = useNavigate();

	const isSmall = useMediaQuery('(max-width:600px)');

	const { handleSubmit } = useFormContext<IFormInput>();
	const onSubmit = () => {
		if (schemaAddress.State === 'RI' && !riQuestionAnswer) {
			setQuestionModal(true)
			return
		}
		setIsPending(true);
		void handleSubmit((data) => {
			setFormData(data);
			if (query) {
				const newCoverageInfoParams = changeFieldsValue(query.coverage_info.parameters, protections, data)
				const quoterResultParams = changeFieldsValue(query.quote_result.coverageDetails.parameters, protections, data)
				const underwritingQuestions = riQuestionAnswer ? [
					...initialPayload.underWriting.questions, {
						questionName: 'Arson', answer: riQuestionAnswer
					}
				] : initialPayload.underWriting.questions
				const queryData: JourneyResult = {
					...query,
					coverage_info: {
						...query.coverage_info,
						parameters: newCoverageInfoParams
					},
					quote_result: {
						...query.quote_result,
						coverageDetails: {
							parameters: quoterResultParams
						},
					},
					underwriting: {
						questions: underwritingQuestions
					}
				}
				if(schemaAddress.State === 'RI') {
					const newPayload = {
						...initialPayload,
						underWriting : {
							questions: underwritingQuestions
						}
					}
					createNext(newPayload)
				}
				if (journeyData) {
					updateData({
						customerJourneyId: journeyData.customerJourneyId,
						customerJourneyResult: JSON.stringify(queryData),
						customerJourneyStatus: 'quoted'
					})
				}
			}
		})()
	}
	useEffect(() => {
		const quoteSuccessRequest = schemaAddress.State === 'RI' ? quoteSuccess : true
		if (fetchSuccess && quoteSuccessRequest) {
			fetchForm(client_id)
			if (query) {
				setApplicationFormData(query);
			}
			onClose()
			setIsPending(false)
			navigate('/checkout');
		}
	}, [fetchSuccess, quoteSuccess]);

	const onClose = () => {
		setRiQuestionAnswer('')
		setQuestionModal(false)
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRiQuestionAnswer((event.target as HTMLInputElement).value);
	};

	return (
		<div>
			<form>
				<DetailsList/>
				<Liability/>
				<BelongingsSlider/>
				<AdditionalProtection/>
				<Deductible/>
				{matchesSM && <Sidebar/>}
				<Grid container justifyContent={isSmall ? "center" : "end"}>
					<Grid item md={4} xs={8} sm={6}>
						<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
							CONTINUE
						</StyledButton>
					</Grid>
				</Grid>
				<ModalWindow open={questionModal} handleClose={onClose} header={
					<Typography variant="h4" mb={0}>Just covering the bases</Typography>
				}
				>
					<Grid item md={12} sm={12} xs={12}>
						<Typography variant="subtitle2" fontWeight="normal" >
							During the last 10 years, has any applicant been formally charged or convicted of any degree of crime or fraud,
							bribery or arson in connection with this or any other property?
						</Typography>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<RadioGroup
							value={riQuestionAnswer}
							onChange={handleChange}
							aria-label="icon-radio"
						>
							<Grid container spacing={2}>
								<Grid item md={6} sm={6} xs={12}>
									<RadioButton
										checked={riQuestionAnswer === 'Y'}
										value={'Y'}
										label="YES"
										label2={""}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<RadioButton
										checked={riQuestionAnswer === 'N'}
										value={'N'}
										label="NO"
										label2={""}
									/>
								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
					<Grid item md={4} xs={8} sm={6}>
						<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
							CONTINUE
						</StyledButton>
					</Grid>
				</ModalWindow>
			</form>
		</div>
	)
}

export default RentersForm
