import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import type { Update } from 'history';
import numeral from 'numeral';
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg'
import Footer from "widgets/Footer/Footer";
import { usePolicyQuery } from 'entities/Policy/hooks/queries';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { history } from 'app/providers/router/ui/NavigationProvider';

const ConfirmationPage = () => {
	const showWeDo = false;
	const query = usePolicyQuery();
	const [email, setEmail] = useState("");
	const [coverageDates, setCoverageDates] = useState("");
	const [policyNumber, setPolicyNumber] = useState("");
	const [policyCost, setPolicyCost] = useState("");
	const [paidToday, setPaidToday] = useState("");
	const navigate = useNavigate();
	const { formData: { plan }, plan: currentPlan, policyStartDate, paymentData } = useContext(FormDataContext);
	const addMonth = plan.period === 4 ? 2 : 1;
	const nextBillingDay = dayjs(policyStartDate).add(addMonth, 'month').format('MM/DD/YYYY');

	useEffect(() => {
		if (!query) return;

		if (query.policyHolderDetails.insured.emailAddress && !email) {
			setEmail(query.policyHolderDetails.insured.emailAddress);
		}
		if (query.transactionDetails.policyEffectiveDate && !coverageDates) {
			const startDate = dayjs(query.transactionDetails.policyEffectiveDate).format('MM/DD/YYYY');
			const endDate = dayjs(query.transactionDetails.policyExpirationDate).format('MM/DD/YYYY');

			const result = startDate.concat(" to ", endDate);
			setCoverageDates(result);
		}
		if (query.transactionDetails.policyId && !policyNumber) {
			setPolicyNumber(query?.transactionDetails.policyId);
		}
		if (query.premiumDetails.premiumAmount && !policyCost) {
			setPolicyCost(`$${query?.premiumDetails.premiumAmount}`);
		}
		if (query.billingDetails.amount && !paidToday) {
			setPaidToday(`$${query?.billingDetails.amount}`);
		}
	}, [query, coverageDates, email, policyNumber, policyCost, paidToday]);

	const click = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, bytes?: string) => {
		e.preventDefault()
		if(!bytes) return
		const byteCharacters = atob(bytes);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const file = new Blob([byteArray], { type: 'application/pdf;base64' });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}



	useEffect(() => {
		history.listen((update: Update) => {
			if (update.action === "POP") {
				history.go(1);
			}
		});
		const client_id = localStorage.getItem('client_id');
		if(!client_id) {
			navigate("/error");
		}
		localStorage.removeItem('client_id');
	}, []);
	return (
		<Container maxWidth={"xl"}>
			<Grid
				container
				spacing={0}
				sx={{ minHeight: '100vh' }}
			>
				<Grid item xs={12}>
					<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5}>
						<Logo width={171} height={40}/>
						<Typography variant="body1" fontSize="10px" lineHeight="12px">Renters Insurance powered by Assurant®</Typography>
					</Box>
					<Container maxWidth={"lg"} sx={{ mt: 5 }}>
						<Box display="flex" alignItems="center" mb={2} justifyContent="center">
							<CheckCircleRoundedIcon
								sx={{ color: "green.main", mr: 1, width: 50, height: 50 }}/>
							<Typography variant={"h3"} align={"center"} fontSize={42} sx={{ mb: 0 }}>Thanks for choosing
								Moved!</Typography>
						</Box>
						<Typography variant={"subtitle2"} color="text.primary" fontWeight={400}>
							You can officially cross renters insurance and phone care off your checklist.
							And we’ll automatically renew your policy - so that’s even less to worry about.
							We sent a receipt with details on your coverage selections and what you paid today
							to <Link href={email} fontWeight={700}>{email || "Testmail@test.com"}</Link>.
							Didn’t receive it? <Link href="#" color="text.secondary" fontWeight={700} underline="hover">
								Click here to resend the email
							</Link>.
							You’ll also see your documents in your inbox within 24 to 48 hours.
						</Typography>
						<Grid container justifyContent="center" spacing={1}>
							<Grid item md={12}>
								<Box border="1px solid grey" borderColor="greyBlue.main" sx={{ p: 2, mt: 4 }}>
									<Box display="flex" alignItems="center" mb={2}>
										<CheckCircleRoundedIcon
											sx={{ color: "green.main", mr: 1, width: 30, height: 30 }}/>
										<Typography
											variant="h3"
											mb={0}
											fontWeight={800}
											fontSize={28}
											color="text.primary"
										>Renters Policy Summary</Typography>
									</Box>
									<Grid container spacing={2} columnSpacing={12}>
										<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
											<Typography variant="subtitle2" fontSize={16}>
												Annual Renters Insurance Cost
												<Typography variant="subtitle2" fontSize={12}>(Excluding Billing Fees)</Typography>
											</Typography>
											<Typography variant="subtitle2" fontSize={16}>{policyCost || "$259.03"}</Typography>
										</Grid>
										<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
											<Typography variant="subtitle2" fontSize={16}>Effective Date</Typography>
											<Typography variant="subtitle2" fontSize={16}>{coverageDates || "11/11/2022 to 11/11/2023"}</Typography>
										</Grid>
										<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
											<Typography variant="subtitle2" fontSize={16}>Your Policy Number</Typography>
											<Typography variant="subtitle2" fontSize={16}>{policyNumber || "REN1115089"}</Typography>
										</Grid>
										<Grid item md={6} sm={12} xs={12} justifyContent="space-between" display="flex">
											<Typography variant="subtitle2" fontSize={16}>Insurance Address</Typography>
											<Typography
												variant="subtitle2"
												fontSize={16}>{paymentData.fullAddress}</Typography>
										</Grid>
										<Grid item md={12} sm={12} xs={12} mt={3}>
											{plan.period !== 1 && <Typography variant="subtitle2" fontSize={16} sx={{fontStyle: 'italic'}}>
												{`Your next payment of ${numeral(currentPlan.installmentAmount || currentPlan.downPaymentAmount || 0)
													.add(currentPlan.fee ?? 0).format('$0,0[.]00')} 
													will be charged automatically on ${nextBillingDay}.`
												}
											</Typography>}
										</Grid>
										{query?.appBytes && (
											<Grid item md={12} sm={12} xs={12} mt={0}>
												<Link
													href={'#'}
													onClick={(e) => click(e,query?.appBytes)}
													color="text.secondary"
													underline="hover"
													target="_blank"
												>
													Download a copy of your Renters Policy Summary</Link>
											</Grid>
										)}
										<Grid item md={12} sm={12} xs={12}>
											Questions? Visit <Link
												href="https://manage.myassurantpolicy.com/"
												color="text.secondary"
												underline="hover">
												manage.myassurantpolicy.com
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item md={12}>
								<Box
									border="1px solid grey"
									borderColor="greyBlue.main"
									sx={{ p: 2, mt: 2 }}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box display="flex" alignItems="center">
										<CheckCircleRoundedIcon
											sx={{ color: "green.main", mr: 1, width: 30, height: 30 }}/>
										<Typography
											variant="h3"
											mb={0}
											fontWeight={800}
											fontSize={32}
											color="text.primary"
										> Total Paid Today</Typography>
									</Box>
									<Typography
										variant="h3"
										mb={0}
										fontWeight={800}
										fontSize={32}
										color="text.primary"
									>{paidToday || "$259.03"}</Typography>
								</Box>
							</Grid>
							{showWeDo && (
								<Grid item md={3} flexDirection="column" display="flex">
									<Box
										border="1px solid grey"
										borderColor="greyBlue.main"
										sx={{ p: 2, mt: 2 }}
										display="flex"
										justifyContent="space-between"
										alignItems="center"
									>
										<Box display="flex" flexDirection="column">
											<Typography
												variant="h4"
												fontWeight={800}
											>Need extra coverage for certain items?</Typography>
											<Typography
												variant={"subtitle2"} color="text.primary" fontWeight={400}
											>
												<Link href={"#"} color="text.secondary" underline="hover">
													Fill out this form
												</Link> to cover your more expensive items( like jewelry, fine art and/or furs).
											</Typography>
										</Box>
									</Box>
									<Typography
										variant="h4"
										textAlign="center"
										mt={2}
										fontWeight={800}
									>How'd We Do?</Typography>
								</Grid>
							)}
						</Grid>
						<Footer/>
					</Container>
				</Grid>
			</Grid>
		</Container>
	)
}

export default ConfirmationPage
