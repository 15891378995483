import { styled } from "@mui/material/styles";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

export const StyledAccordion = styled(Accordion)(() => ({
	border: 0,
	boxShadow: 'none',
	backgroundColor: 'transparent',
	'&:before': {
		display: 'none',
	},
	"&.Mui-expanded": {
		margin: 0,
	},
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	backgroundColor: 'transparent',
	padding: 0,
	justifyContent: 'start',
	"& .MuiAccordionSummary-content": {
		flex: '0 0 auto',
		alignItems: 'center',
		margin: '20px 20px 20px 0px !important',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	"&.Mui-expanded": {
		borderBottom: '0!important',
		margin: 0,
		minHeight: '30px',
	},
	"&.MuiAccordionSummary-content.Mui-expanded": {
		marginRight: '20px!important',
	},
	minHeight: '30px',
	borderBottomColor: theme.palette.greyBlue.main,
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	padding: 0,
	backgroundColor: 'transparent',
	borderBottomColor: theme.palette.greyBlue.main,
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	paddingBottom: '20px'
}));

export const AdditionalItem = styled('div', { shouldForwardProp: (propName) => propName !== 'disabled' })<{
	disabled?: boolean
}>(({ theme, disabled }) => ({
	display: 'flex',
	position: 'absolute',
	right: 0,
	top: '30%',
	zIndex: 999,
	pointerEvents: disabled ? 'none' : 'auto',
	opacity: '1',
	fontSize: '12px',
	alignItems: 'center',
	color: theme.palette.text.secondary
}))
export const AdditionalItemIcon = styled('div')(() => ({
	color: '#FFFFFF',
	width: '15px',
	height: '15px',
	borderRadius: '50%',
	display: 'flex',
	marginLeft: '15px',
	padding: '5px',
	"& svg": {
		width: '15px',
		height: '15px',
	}
}))

export const AdditionalSmallItemIcon = styled('div')(() => ({
	color: '#FFFFFF',
	width: '8px',
	height: '8px',
	borderRadius: '50%',
	display: 'flex',
	marginRight: '8px',
	padding: '5px',
	"& svg": {
		width: '8px',
		height: '8px',
	}
}))

export const AdditionalIncludedItemIcon = styled('div')(() => ({
	color: '#FFFFFF',
	width: '15px',
	height: '15px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	padding: '5px',
	"& svg": {
		width: '25px',
		height: '25px',
	}
}))
