import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DisclosureResponse } from 'shared/api/assurant/disclosures/types';
import Disclosures from 'shared/api/assurant/disclosures';

export const useDisclosuresMutationQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: Disclosures.getData,
		onSuccess: data => {
			queryClient.setQueryData(['disclosureData'], data.data)
		},
		onError: error => {
			console.log('something goes wrong', error);
		}
	})
}
export const useDisclosuresQuery = () => {
	const client = useQueryClient();
	return client.getQueryData<DisclosureResponse>(['disclosureData']);
}
