import dayjs from 'dayjs';
import { Box, Button, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormDataContext } from '../../../app/providers/formValues/lib/FormDataContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { parse, parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	coverageData: string;
}

const EditableDate = (props: Props) => {
	const { setPolicyStartDate } = useContext(FormDataContext);
	const [dateEditable, setDateEditable] = useState(false);
	const prevDate = useRef<string>('');
	const { coverageData } = props

	const handleEffectiveDate = (data: string | null) => {
		setPolicyStartDate(dayjs(data).format('YYYY-MM-DD'))
		prevDate.current = dayjs(data).format('YYYY-MM-DD')
	}

	const handleEdit = () => {
		setDateEditable(true)
	}
	const handleClose = () => {
		setDateEditable(false)
	}
	useEffect(() => {
		if(prevDate.current === dayjs(coverageData).format('YYYY-MM-DD')) {
			setDateEditable(false)
		}
	}, [coverageData]);
	const eighteenYearsAgo = dayjs().add(90, 'days').format('YYYY-MM-DD');
	return (
		<div style={{ width: '100%' }}>
			{dateEditable ? (
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<DatePicker
						sx={{flex: '0 0 80%'}}
						value={parseISO(coverageData) as unknown as string}
						maxDate={new Date(eighteenYearsAgo) as unknown as string}
						label="Coverage Start Date"
						format={"yyyy-MM-dd"}
						onChange={handleEffectiveDate}
						disablePast
					/>
					<div>
						<IconButton aria-label="edit" size="medium" onClick={handleClose}>
							<CloseIcon fontSize="inherit"/>
						</IconButton>
					</div>
				</Box>
			) : (
				<Box display="flex" alignItems="center" sx={{ justifyContent: 'space-between', }}>
					<Typography>Coverage Start Date</Typography>
					<Typography fontWeight={800} textAlign="right">
						<IconButton aria-label="edit" size="small" onClick={handleEdit}>
							<EditIcon fontSize="inherit"/>
						</IconButton>
						{coverageData && dayjs(coverageData).format('MM/DD/YYYY')}

					</Typography>
				</Box>
			)}
		</div>
	)
}

export default EditableDate
