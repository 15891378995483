import * as React from "react";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { StyledDeleteIcon, StyledButton, StyledBadge } from "./styles";

interface Props {
	name: string;
	onClick?: () => void;
	onDelete: () => void;
}

const BadgeButton = ({ name, onClick, onDelete }: Props) => {
	return (
		<Stack spacing={3} direction="row">
			<StyledBadge
				color="secondary"
				badgeContent={
					<IconButton onClick={onDelete} sx={{ padding: '0', backgroundColor: 'transparent' }}>
						<StyledDeleteIcon />
					</IconButton>
				}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				{<StyledButton onClick={onClick}>{name}</StyledButton>}
			</StyledBadge>
		</Stack>
	);
}

export default BadgeButton;
