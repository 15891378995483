import React, { ReactNode } from 'react'
import { ModalItem } from "shared/ui/ModalWindow/styles";
import { DialogTitleStyled } from "../Dialog";
import { DialogActions, DialogContent, Grid, IconButton, Modal, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
	children: ReactNode,
	open: boolean,
	handleClose: () => void,
	header: string | ReactNode
	actions?: string | ReactNode
}
const ModalWindow = ({children, open, handleClose, header, actions}: Props) => {
	const isSmall = useMediaQuery('(max-width:600px)')
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			sx={{overflowY:'scroll'}}
		>
			<ModalItem sx={{width: isSmall ? '100%' : '50%'}}>
				<DialogTitleStyled>
					{header}
				</DialogTitleStyled>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon/>
				</IconButton>
				<DialogContent sx={{ height: '100%' }} dividers>
					<Grid container spacing={2} justifyContent="center">
						{children}
					</Grid>
				</DialogContent>
				{actions && <DialogActions>{actions}</DialogActions>}
			</ModalItem>
		</Modal>
	)
}

export default ModalWindow
