/* eslint-disable max-len */
const tokenParams = {
	_client_id: "70f8f8ad-b8b9-4205-b92f-ecd005681016",
	_grant_type: 'public',
	_scope: null,

	get clientId() {
		return this._client_id;
	},

	set clientId(id) {
		this._client_id = id;
	},
	
	get scope() {
		return this._scope;
	},
	
	set scope(scope) {
		this._scope = scope;
	},

	get params() {
		return {
			client_id: this._client_id,
			grant_type: this._grant_type,
			scope: this._scope,
		};
	},
};

export {tokenParams};
